<template>
  <div style="background-color:#f3f3f3;">
    <van-overlay :show="overlayShow">
      <van-loading  class="van-loading"/>
    </van-overlay>
    <div class="cont">
      <div class="top_title">
        你可以将常用的5个功能添加到网格果果首页
        <br/>
        也可以长按拖动调整提醒的顺序
      </div>
      <div class="drag-list">
        <draggable v-model="dataList" :options="options">
          <div v-for="item in dataList" :key="item.menuId" class="drag-item">
            <p class="drag-item-count">
              <van-icon :name="item.icon ? setImgUrl(item.icon) : 'info'" color="#387FF5" size="0.8rem" class="drag-item-icon"/>
              {{item.count}}
            </p>
            <span class="drag-item-name">{{item.name}}</span>
            <van-icon name="clear" class="drag-item-close" color="#ccc" size="0.4rem" @click="delToList(item.menuId)"/>
          </div>
        </draggable>
      </div>
    </div>
    <div class="cont">
      <div class="top_title">
        <h3>
          全部功能
        </h3>
      </div>
      <div v-for="val in allDataList" :key="val.menuId">
        <div style="margin:20px;font-size:14px">{{val.name}}</div>
        <van-grid :column-num="4">
          <van-grid-item v-for="item in val.list" :key="item.menuId" v-show="listShow(item.menuId)">
            <van-icon name="add" class="drag-item-success" color="#387FF5" size="0.4rem" @click="addTOList(item)"/>
            <p class="drag-item-count">
              <van-icon :name="item.icon ? setImgUrl(item.icon) : 'info'" color="#387FF5" size="0.8rem" class="drag-item-icon"/>
              {{item.count}}
            </p>
            <span class="drag-item-name">{{item.name}}</span>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <div class="btn">
      <van-col :span="24">
        <van-button type="info" size="large" square @click="submit">确认更改</van-button>
      </van-col>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {getImageStream} from '@/utils/index'
export default {
name: "manage-remind",
  components: {draggable},
  data () {
    return {
      overlayShow: false,
      options: {
        delayOnTouchOnly: true,  //开启触摸延时
        direction: 'vertical',   //拖动方向
        delay: 500,              //延时时长
        touchStartThreshold: 3,  //防止某些手机过于敏感(3~5 效果最好)
        chosenClass: 'chosen',    //选中之后拖拽项添加的class名(用于选中时候添加样式)
        animation: "300"
      },
      dataList: [],
      allDataList: [{list: []}]
    }
  },
  methods: {
    listShow (menuId) {
      return !this.dataList.some(item => item.menuId === menuId)
    },
    setImgUrl (path) {
      return getImageStream(path,process.env.VUE_APP_BASE_URL)
    },
    submit () {
      let ids = this.dataList.map(item => {
        return {
          value: item.appId ? item.appId : item.menuId,
          parameter: (item.appId || item.target == 2) ? '3' : '2'
        }
      })
      // if (ids.length !== 8) {
      //   return this.$toast.fail('请选择八个提醒')
      // }
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/message/remind/config/jmgg/commonSave'),
        method: 'post',
        data: this.$http.adornData(ids, false),
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId
        }, false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success('修改成功')
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getAllList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/getMyAppList'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.allDataList.map(item => {
            item.list = data.list.list.map(val => {
              return {
                ...val,
                icon: val.appIcon,
                name: val.appName,
                menuId: val.appId
              }
            })
          })
          // this.getAppList()
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getAppList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/appauth'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$globalData.backToken = data.token
          this.$httpBack({
            url: this.$httpBack.adornUrl('/app/application/info/myApp'),
            method: 'get',
            params: this.$httpBack.adornParams({
              'page': 1,
              'limit': -1,
              'belongs': 2
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              let allMenuList = []
              this.allDataList.map(item => {
                if(item.list && item.list.length > 0) {
                  item.list.map(it => {
                    allMenuList.push(it)
                  })
                }
              })
              allMenuList.map(item => {
                if(item.code && item.code.indexOf('&') != -1) {
                  item.code = item.code.replace('wggg&','')
                }
              })
              let tepArrA = []
              let tepArrB = []
              data.page.list.map(item => {
                if(item.appCode == 'sjgl_xcjl') {
                  tepArrA.push(item)
                }else if(item.appCode == 'sjgl_wtjl') {
                  tepArrA.push(item)
                }else if(item.appCode == ' sjgl_sgzf_zfjl') {
                  tepArrA.push(item)
                }else if(item.appCode == 'sjgl_bxjl') {
                  tepArrA.push(item)
                }else if(item.appCode == 'yy_bszn') {
                  tepArrA.push(item)
                }else if(item.appCode == 'sqgg') {
                  tepArrA.push(item)
                }else if(item.appCode == 'sjgl_hdjl') {
                  tepArrA.push(item)
                }else if(item.appCode == 'wjda') {
                  tepArrA.push(item)
                }else {
                  tepArrB.push(item)
                }
              })
              tepArrA = tepArrA.filter(item => {
                return allMenuList.every(it => {
                  return item.appCode != it.code
                })
              })
              let list = tepArrB.concat(tepArrA)
              this.allDataList.map(item => {
                if (item.code == 'yy') {
                  item.list = item.list.concat(list.map(val => {
                    return {
                      ...val,
                      icon: val.appIcon,
                      name: val.appName,
                      menuId: 'app' + val.appId
                    }
                  }))
                }
              })
            }
          })
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
    getDataList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/message/remind/config/jmgg/myCommonMenuList'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$orgId,
          userId: this.$globalData.userInfo.userId
        })
      }).then(({data}) => {
        this.overlayShow = false
        if (data && data.code === 0) {
          if (this.$orgId == 16422){
            data.myList.forEach((item, index) => {
              if(item.name === '问题'){data.myList.splice(index, 1)}
            })
            data.allMyList.list.forEach((item, index) => {
              if(item.appName === '问题'){data.allMyList.list.splice(index, 1)}
            })
          }
          this.dataList = data.myList || []
          this.allDataList.map(item => {
            item.list = data.allMyList.list.map(val => {
              return {
                ...val,
                icon: val.appIcon,
                name: val.appName,
                menuId: val.appId
              }
            })
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
      // this.getAllList()
    },
    addTOList (val) {
     let flag =  this.dataList.some(item => item.menuId === val.menuId )
      if (flag || this.dataList.length >= 5) {
        return this.$toast.fail('列表已满或该项已在列表中!!!')
      }
      this.dataList.push(val)
    },
    delToList (menuId) {
      this.dataList.forEach((item, index) => {
        if (item.menuId === menuId) {
          this.dataList.splice(index, 1)
        }
      })
    }
  },
  created() {
    this.overlayShow = true
    this.getDataList()
  }
}
</script>

<style scoped lang="scss">
::v-deep .van-grid-item__content {
  padding: 30px 0 10px 0;
}
.van-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}
.drag-list {
  /*防止长按系统默认弹窗*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  width: 75%;
  margin: 0 auto;
}
.drag-item {
  position: relative;
  display: inline-block;
  background-color: #fff;
  height: 180px;
  width: 33%;
  line-height: 60px;
  text-align: center;
  margin-top: -1px;
  border: 1px solid #ebedf0;
  padding: 30px 0 0 0 ;
}
.drag-item-count {
  margin: 0;
  font-size: 32px;
  line-height: 80px;
}
.drag-item-name {
  display: inline-block;
  font-size: 26px;
  line-height: 50px;
  color: #9e9d9d;
}
.drag-item-icon {
  margin-top: -10px;
  margin-right: 5px;
}
.drag-item-close, .drag-item-success {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}
.chosen {
  background-color: #ccc;
}
.cont{
  margin-top: 30px;
  padding-bottom: 160px;
  background-color: #fff;
  .top_title {
    text-align: center;
    font-size: 26px;
    height: 100px;
    line-height: 35px;
    padding-top: 20px;
    h3 {
      margin: 15px 0 ;
      font-weight: 800;
    }
  }
}
.btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0;
  z-index: 2;
  width: 100%;
  height: 160px;
  background-color: #fff;
}
</style>
